import React from "react";
import clsx from "clsx";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { parseQuote } from "@/utilities/htmlParser";
import { Grid } from "../shared/Grid";

type QuoteBlockProps = GetDynamicContentItemProps<"QuoteBlock">;

const formatQuote = (text: string) => text.replace(/"([^"]*)"/g, "“$1”");

const QuoteBlock: React.FC<QuoteBlockProps> = ({
  html_text: htmlText,
  quote_source: quoteSource,
  className,
}) => {
  if (!htmlText) return null;
  return (
    <Grid dataBlock="QuoteBlock" className={clsx("relative", className)}>
      <div className="bg-grey-light tablet:col-span-8 tablet:col-start-3 tablet:p-12 col-span-full px-4 py-8 text-center">
        <div className="text-forms tablet:text-h3-sm font-sans font-bold tracking-wider">
          {parseQuote(formatQuote(htmlText))}
        </div>
        {quoteSource && (
          <div className="text-grey-dark mt-3 font-serif text-2xl font-thin tracking-wide">
            {quoteSource}
          </div>
        )}
      </div>
    </Grid>
  );
};

export default QuoteBlock;
