import Button from "@/components/shared/Button";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { Grid } from "@/components/shared/Grid";
import ResponsiveImage from "@/components/shared/ResponsiveImage";
import { useButtonTarget } from "@/hooks/useButtonTarget";

export type TeamBlockProps = GetDynamicContentItemProps<"TeamBlock">;

const TeamBlock: React.FC<TeamBlockProps> = ({
  title,
  body,
  image: preprImage,
  dark,
  button_text,
  button_target,
  className,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target?.[0]);
  const image = preprImage?.[0];

  return (
    <Grid
      dataBlock="TeamBlock"
      className={`tablet:py-20 relative flex flex-col py-12`}
      containerClass={`${dark ? "bg-primary-black" : ""} ${className ?? ""}`}
    >
      <div className="tablet:col-span-6 tablet:h-full relative col-span-full w-full">
        {image?.default && (
          <ResponsiveImage src={image?.default} alt={image.description ?? "Team image"} />
        )}
      </div>

      <div className="tablet:col-span-5 tablet:col-start-8 tablet:mt-0 col-span-full mt-10 self-center">
        <h2
          className={`text-h2-sm tablet:text-h4 font-bold tracking-wider ${dark && "text-white"}`}
        >
          {title}
        </h2>
        <p
          className={`text-p-sm tablet:text-p mt-6 font-serif tracking-wider ${
            dark && "text-white"
          }`}
        >
          {body}
        </p>
        {button_text && isValid && (
          <Button
            type={type}
            href={href}
            onClick={onClick}
            variant="tertiary"
            className="tablet:mt-12 mt-10"
          >
            {button_text}
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default TeamBlock;
