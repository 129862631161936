import React from "react";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Grid } from "../shared/Grid";
import Button from "../shared/Button";
import CustomerExperienceCarouselMobile from "./CustomerExperienceCarousel/CustomerExperienceCarouselMobile";
import CustomerExperienceCarouselDesktop from "./CustomerExperienceCarousel/CustomerExperienceCarouselDesktop";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

export type CustomerExperienceCarouselProps =
  GetDynamicContentItemProps<"CustomerExperienceCarouselBlock">;

const CustomerExperienceCarousel: React.FC<CustomerExperienceCarouselProps> = ({
  title,
  customer_stories,
  button_text,
  button_target,
  card_button_text,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target[0]);
  return (
    <div data-block="CustomerExperienceCarousel" className="gradient-2 tablet:py-32 py-14">
      <Grid>
        {title && (
          <h2 className="text-h2-sm tablet:col-span-12 tablet:col-start-2 tablet:text-h3 col-span-full font-sans font-bold tracking-wider text-white">
            {title}
          </h2>
        )}
      </Grid>
      <div className="tablet:hidden block">
        <CustomerExperienceCarouselMobile
          customerStories={customer_stories}
          cardButtonText={card_button_text}
        />
      </div>
      <div className="tablet:block hidden">
        <CustomerExperienceCarouselDesktop
          customerStories={customer_stories}
          cardButtonText={card_button_text}
        />
      </div>
      {isValid && button_text && (
        <Grid>
          <div className="tablet:col-span-4 tablet:col-start-2 tablet:justify-start col-span-full mt-20 flex justify-center">
            <Button type={type} href={href} onClick={onClick} variant="tertiary">
              {button_text}
            </Button>
          </div>
        </Grid>
      )}
    </div>
  );
};

export default CustomerExperienceCarousel;
