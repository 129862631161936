import React from "react";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import BlogCaption from "@/components/pages/BlogOverview/BlogOverviewBlock/BlogCaption";
import Button from "@/components/shared/Button";
import { Grid } from "@/components/shared/Grid";
import ResponsiveImage from "@/components/shared/ResponsiveImage";

export type SpotlightBlogBlockProps = GetDynamicContentItemProps<"SpotlightBlogBlock">;

const SpotlightBlogBlock: React.FC<SpotlightBlogBlockProps> = ({
  intro_text,
  button_text,
  blog,
  className,
}) => {
  const { image, type, _publish_on, card_title, _slug } = blog?.[0] ?? {};
  const spotlightImage = image?.[0]?.default;
  const href = _slug || "/404";
  return (
    <section data-block="SpotlightBlogBlock" className={`relative ${className ?? ""}`}>
      <Grid className="drop-shadow-card mx-4 bg-white px-0">
        {spotlightImage && (
          <div className="tablet:col-span-6 relative col-span-4 self-center overflow-hidden">
            <ResponsiveImage src={spotlightImage} alt={image?.[0]?.description ?? "Blog image"} />
            <div className="bg-secondary-burgundy tablet:left-10 absolute left-4 top-0 rounded-b-[4px] px-4 py-1">
              <p className="text-p-sm font-serif tracking-wider text-white">Uitgelicht</p>
            </div>
          </div>
        )}
        <div className="tablet:col-span-6 tablet:mt-0 tablet:pl-6 tablet:pr-14 col-span-4 self-center px-4 py-8">
          <BlogCaption type={type} publishOn={_publish_on} />
          {card_title && (
            <h2 className="tablet:10 text-h3-sm tablet:mt-10 tablet:text-h2-sm mt-6 font-sans font-bold tracking-wider">
              {card_title}
            </h2>
          )}
          {intro_text && (
            <p className="text-p-sm tablet:mt-6 tablet:text-p mt-4 font-serif tracking-wider">
              {intro_text}
            </p>
          )}
          {button_text && (
            <Button variant="secondary" size="small" className="mt-8" type="link" href={href}>
              {button_text}
            </Button>
          )}
        </div>
      </Grid>
    </section>
  );
};

export default SpotlightBlogBlock;
