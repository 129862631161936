import React from "react";
import Accordion from "@/components/shared/Accordion";
import Button from "@/components/shared/Button";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { FaqBlockProps } from "../FaqMiniBlock";

type ButtonTarget = Path<FaqBlockProps, ["faq_button_target", number]>;

interface FaqProps {
  title?: string | null;
  items: { title: string; content: React.ReactNode }[];
  buttonText?: string | null;
  buttonTarget?: ButtonTarget;
  className?: string;
}

const Faq: React.FC<FaqProps> = ({ title, items, buttonText, buttonTarget, className }) => {
  const { type, href, onClick, isValid } = useButtonTarget(buttonTarget);

  if (!items) return null;
  return (
    <div
      className={`tablet:col-span-7 tablet:p-10 desktop-s:py-20 desktop-s:px-32 col-span-full ${
        className ?? ""
      }`}
    >
      {title && <h4 className="text-h3-sm tablet:text-h4 font-bold tracking-wider">{title}</h4>}
      <Accordion items={items} className="tablet:mt-12 mt-8" />
      {buttonText && isValid && (
        <Button
          type={type}
          href={href}
          onClick={onClick}
          variant="secondary"
          className="tablet:my-10 my-8"
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Faq;
