import React from "react";
import BlogCard, { BlogCardProps } from "@/components/shared/BlogCard";
import { CardListOrCarousel } from "@/components/shared/CardListOrCarousel/CardListOrCarousel";

interface BlogsEntryBlockCardsProps {
  data: (BlogCardProps | null)[];
}

const BlogsEntryBlockCards: React.FC<BlogsEntryBlockCardsProps> = ({ data }) => {
  return (
    <div className="tablet:py-20 overflow-hidden pb-9 pt-7">
      <CardListOrCarousel
        data={data}
        renderItem={(item) => {
          if (!item) return null;
          return <BlogCard {...item} />;
        }}
        listClass="col-span-4 flex"
      />
    </div>
  );
};

export default BlogsEntryBlockCards;
