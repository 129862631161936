/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
interface TrengoExtension extends Window {
  Trengo: TrengoWindow | undefined;
}

type Channel = "chat" | "whatsapp";

declare const window: TrengoExtension;

interface TrengoWindow {
  Api: TrengoApi;
}

interface TrengoWidget {
  open: (channel: Channel) => void;
  close: () => void;
  render: () => void;
}

interface TrengoApi {
  Widget: TrengoWidget;
}

class Trengo {
  static openWidget(channel: Channel) {
    if (!!window?.Trengo?.Api) {
      window.Trengo.Api.Widget.open(channel);
    }
  }

  static renderWidget() {
    if (!!window?.Trengo?.Api) {
      window.Trengo.Api.Widget.render();
    } else {
      console.error("Could not render Trengo widget");
    }
  }

  static closeWidget() {
    if (!!window?.Trengo?.Api) {
      window.Trengo.Api.Widget.close();
    }
  }
}
export default Trengo;
