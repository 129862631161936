import React from "react";
import ArrowRightTwo from "@/components/Icons/ArrowRightTwo";

type TabSelectorProps = {
  tabTitle: string;
  selectedTabTitle?: string | null;
  onClickTab: (selectedTabTitle: string) => () => void;
};

const TabSelector: React.FC<TabSelectorProps> = ({ tabTitle, selectedTabTitle, onClickTab }) => {
  return (
    <li className="group px-12">
      <button
        className="border-grey-asphalt flex w-full justify-between border-b py-8"
        onClick={onClickTab(tabTitle)}
      >
        <span
          className={`transition-1 text-h4-sm font-sans font-bold tracking-wider ${
            selectedTabTitle === tabTitle
              ? "text-primary-black"
              : "text-grey-asphalt group-hover:text-primary-black"
          }`}
        >
          {tabTitle}
        </span>
        <ArrowRightTwo
          className={`transition-1 text-secondary-burgundy ${
            selectedTabTitle === tabTitle ? "opacity-100" : "opacity-0 group-hover:opacity-100"
          }`}
        />
      </button>
    </li>
  );
};

export default TabSelector;
