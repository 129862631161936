import React from "react";
import { Calculator } from "./Calculator";
import { Catalog } from "./Catalog";
import { Locator } from "./Locator";

export type HelloRiderWidget = "CALCULATOR" | "CATALOG" | "LOCATOR";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: HelloRiderWidget;
}

const HelloRiderBlock: React.FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case "CALCULATOR":
      return <Calculator {...props} />;
    case "CATALOG":
      return <Catalog {...props} />;
    case "LOCATOR":
      return <Locator {...props} />;
    default:
      return null;
  }
};

export default HelloRiderBlock;
