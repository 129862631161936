import { Grid } from "@/components/shared/Grid";
import ActionCard, { ActionCardProps } from "@/components/blocks/ActionCardGrid/ActionCard";

type Props = {
  cardOneProps: ActionCardProps;
  cardTwoProps: ActionCardProps;
  className?: string;
};
export const ActionCardGrid = ({ cardOneProps, cardTwoProps, className }: Props) => {
  return (
    <Grid dataBlock="ActionCardGrid" className={className}>
      <div className="tablet:flex-row desktop-xl:col-start-2 col-span-full flex flex-col">
        <ActionCard {...cardOneProps} />
        <ActionCard {...cardTwoProps} />
      </div>
    </Grid>
  );
};
