import { Path } from "@/utilities/types";
import { parseText } from "@/utilities/htmlParser";
import { FaqBlockProps } from "../FaqMiniBlock";

export type FaqItems = Path<FaqBlockProps, ["faq_items"]>;

export const mapItems = (faq_items: FaqItems) =>
  faq_items
    ?.filter(
      (item): item is { question: string; answer: string } => !!item?.question && !!item?.answer
    )
    .map(({ question, answer }) => ({ title: question, content: answer && parseText(answer) })) ||
  null;
