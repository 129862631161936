import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const Container: React.FC<Props> = ({ children, className }) => (
  <div className={`flex w-full justify-center ${className ?? ""}`}>
    <div className="max-w-screen-desktop-xl tablet:px-8 w-full px-4">{children}</div>
  </div>
);

export default Container;
