import { Grid } from "@/components/shared/Grid";
import Highlights from "@/components/shared/Highlights";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type HighlightsBlockProps = GetDynamicContentItemProps<"HighlightsBlock">;

const HighlightsBlock: React.FC<HighlightsBlockProps> = ({
  title,
  highlights,
  dark_theme_enabled,
  className,
}) => {
  if (!highlights?.length) return null;
  return (
    <section data-block="HighlightsBlock" className={`relative ${className ?? ""}`}>
      <Grid className={`${dark_theme_enabled ? "bg-grey-light tablet:py-20 py-10" : ""}`}>
        {title && (
          <h2 className="text-h2-sm tablet:mb-12 col-span-full mb-10 text-center font-sans font-bold tracking-wider">
            {title}
          </h2>
        )}
        <Highlights highlights={highlights} />
      </Grid>
    </section>
  );
};

export default HighlightsBlock;
