import Script from "next/script";
import React from "react";
import { getPublicStandaloneProps } from "@/getPublicStandaloneProps";

const TrengoWidget: React.FC = () => {
  // NOTE: if inline-script below is modified, you must also update the corresponding ContentSecurityPolicy sha256 hash
  return (
    <>
      <Script id="init-trengo" strategy="afterInteractive">
        {`
          window.Trengo = window.Trengo || {};
          window.Trengo.render = false;
          window.Trengo.key = "${getPublicStandaloneProps().trengoKey}";
     `}
      </Script>
      <Script
        id="include-trengo-script"
        src={"https://static.widget.trengo.eu/embed.js"}
        strategy="afterInteractive"
      ></Script>
    </>
  );
};

export default TrengoWidget;
