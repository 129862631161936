import React from "react";
import styles from "./PageHeaderCircle.module.css";

const PageHeaderCircle: React.FC = () => {
  return (
    <div className="tablet:-top-14 tablet:left-1/2 tablet:w-3/4 tablet:-translate-x-64 absolute left-16 top-8 -z-10 w-full">
      <div className={styles.circle} />
    </div>
  );
};

export default PageHeaderCircle;
