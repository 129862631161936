import type { FaqAccordionItem } from "@/services/prepr/queries/getFaqSubjects";
import Accordion from "@/components/shared/Accordion";
import { parseText } from "@/utilities/htmlParser";
import Button from "@/components/shared/Button";
import { useDialogForms } from "@/components/Layout/useDialogForms";
import { unpackButtonTarget } from "@/hooks/useButtonTarget";

interface Props {
  items?: FaqAccordionItem[] | null;
  className?: string;
}

const FaqAccordion: React.FC<Props> = ({ items, className }) => {
  const dialogForms = useDialogForms();

  if (!items) return null;
  const accordionItems = items.map(({ question, answer, button_target, button_text }) => {
    const { type, href, onClick, isValid } = unpackButtonTarget(button_target?.[0], dialogForms);
    return {
      title: question,
      content: (
        <>
          {answer && parseText(answer)}
          {button_text && isValid && (
            <Button
              variant="tertiary"
              size="small"
              type={type}
              href={href}
              onClick={onClick}
              className="tablet:mt-8 mt-6"
            >
              {button_text}
            </Button>
          )}
        </>
      ),
    };
  });
  return (
    <Accordion
      items={accordionItems}
      className={className}
      itemLimit={{
        minimum: 5,
        button: { moreItemsText: "Toon meer +", lessItemsText: "Toon minder -" },
      }}
    />
  );
};

export default FaqAccordion;
