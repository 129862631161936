import {
  allowedDynamicContentItemTypenames,
  DynamicContentItem,
  DynamicContentItemTypename,
  FilteredDynamicContentItem,
  FilteredDynamicContentItemTypename,
} from "./types";

export const checkIfTypenameIsAllowed = (typename?: DynamicContentItemTypename) =>
  allowedDynamicContentItemTypenames?.includes(typename as FilteredDynamicContentItemTypename);

export const filterProps = ({
  item,
  prevTypename,
}: {
  item: DynamicContentItem;
  prevTypename?: DynamicContentItemTypename;
}) => {
  return {
    item:
      item?.__typename && checkIfTypenameIsAllowed(item.__typename)
        ? (item as FilteredDynamicContentItem)
        : undefined,
    prevTypename: checkIfTypenameIsAllowed(prevTypename)
      ? (prevTypename as FilteredDynamicContentItemTypename)
      : undefined,
  };
};
