import React from "react";
import Button from "@/components/shared/Button";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { InformationTabsBlockProps } from "../../InformationTabsBlock";

type TabContentProps = Path<InformationTabsBlockProps, ["tabs", 0]>;

const TabContent: React.FC<TabContentProps> = ({ title, content, button_text, button_target }) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target[0]);

  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <h3 className="text-h3-sm text-secondary-burgundy mb-4 font-bold tracking-wider">
          {title}
        </h3>
        <p className="text-p-sm font-serif tracking-wider">{content}</p>
      </div>
      {button_text && isValid && (
        <Button
          variant="secondary"
          className="mt-10 self-start"
          type={type}
          href={href}
          onClick={onClick}
        >
          {button_text}
        </Button>
      )}
    </div>
  );
};

export default TabContent;
