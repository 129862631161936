import React from "react";
import { useSwiper } from "swiper/react";
import CardIconButton from "@/components/shared/CardIconButton";
import useHoverState from "@/hooks/useHoverState";

interface CarouselButtonsProps {
  activeIndex: number;
}

const CarouselButtons: React.FC<CarouselButtonsProps> = ({ activeIndex }) => {
  const [leftArrowIsHovered, setLeftArrowIsHovered] = useHoverState();
  const [rightArrowIsHovered, setRightArrowIsHovered] = useHoverState();
  const swiper = useSwiper();

  return (
    <div className="pointer-events-none absolute left-1/2 top-1/2 z-10 -mt-10 flex w-1/2 max-w-[960px] -translate-x-1/2 -translate-y-1/2 justify-between">
      <button
        {...setLeftArrowIsHovered}
        onClick={() => swiper.slidePrev()}
        className="tablet:w-14 pointer-events-auto w-12"
      >
        <CardIconButton
          whiteArrow={true}
          className={`tablet:p-3 tablet:hover:pl-4 tablet:hover:pr-2 rotate-180 scale-150 ${
            activeIndex === 0 ? "opacity-0" : "opacity-100"
          }`}
          isHovered={leftArrowIsHovered}
        />
      </button>
      <button
        {...setRightArrowIsHovered}
        onClick={() => swiper.slideNext()}
        className="tablet:w-14 pointer-events-auto w-12"
      >
        <CardIconButton
          whiteArrow={true}
          className={`tablet:p-3 tablet:hover:pl-4 tablet:hover:pr-2 scale-150 ${
            swiper.isEnd ? "opacity-0" : "opacity-100"
          }`}
          isHovered={rightArrowIsHovered}
        />
      </button>
    </div>
  );
};

export default CarouselButtons;
