import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import InformationTabsBlockDesktop from "./InformationTabsBlock/InformationTabsBlockDesktop";
import InformationTabsBlockMobile from "./InformationTabsBlock/InformationTabsBlockMobile";

export type InformationTabsBlockProps = GetDynamicContentItemProps<"InformationTabsBlock">;

const InformationTabsBlock: React.FC<InformationTabsBlockProps> = (props) => {
  return (
    <>
      <div
        data-block="InformationTabsBlock"
        className="bg-primary-black/[.02] tablet:block hidden py-[8.5rem]"
      >
        <InformationTabsBlockDesktop {...props} />
      </div>
      <div className="bg-primary-black/[.02] tablet:hidden block py-14">
        <InformationTabsBlockMobile {...props} />
      </div>
    </>
  );
};

export default InformationTabsBlock;
