import Script from "next/script";
import clsx from "clsx";
import { Grid } from "@/components/shared/Grid";

export const Catalog: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...props }) => (
  <Grid>
    <div {...props} className={clsx("col-span-full", props?.className)}>
      <div id="hellorider-catalog"></div>
      <Script
        id="hellorider-catalog-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            initializeCatalogWidget({
              "online_order": true,
              "auth_token": "183ea76d-5640-4205-89dc-213f7fe0ce11", // gitleaks:allow
              "color": "#0b1423",
              "per_page": 12,
              "register_url": "/register",
              "find_dealer_url": "/fiets/vind-dealer",
              "language": "nl",
              "default_filters": [
              "brand_id",
              "bike_type_id",
              "price_range",
              "frame_type",
              ],
              "max_filters_before_more": 3,
              "additional_filters": [
              "gear_type",
              "brake_type",
              "ebike_type_id",
              "motor_position",
              "motor_technology",
              "battery_position"
              ],
              "element_id": "hellorider-catalog"
            })
          `,
        }}
      ></Script>
    </div>
  </Grid>
);
