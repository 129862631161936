import React from "react";
import { parseQuote } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import Container from "../shared/Container";
import BlogCard from "../shared/BlogCard";
import { CardListOrCarousel } from "../shared/CardListOrCarousel/CardListOrCarousel";

export type MoreCustomerExperiencesEntryBlockProps =
  GetDynamicContentItemProps<"MoreCustomerExperiencesEntryBlock">;

const MoreCustomerExperiencesEntryBlock: React.FC<MoreCustomerExperiencesEntryBlockProps> = ({
  title,
  customer_stories,
  button_text,
  className,
}) => {
  if (!customer_stories) return null;
  return (
    <section
      data-block="MoreCustomeExperienceEntryBlock"
      className={`bg-grey-light relative pb-2 ${className ?? ""}`}
    >
      <Container className="self-center">
        <h2 className="text-h2-sm tablet:mb-10 tablet:text-h2-sm my-10 text-center font-sans font-bold tracking-wider ">
          {title}
        </h2>
      </Container>
      <CardListOrCarousel
        data={customer_stories}
        renderItem={({ quote: customQuote, items: customerStory }) => {
          const { _slug, caption, image, quote: originalQuote } = customerStory?.[0] ?? {};
          const customerImage = image?.[0]?.default;
          const quote = customQuote ? customQuote : originalQuote;
          if (!_slug || !customerImage || !quote || !caption) return null;
          return (
            <BlogCard
              className="mb-10"
              link={_slug}
              caption={
                <p className="text-p-xs tablet:text-p font-serif tracking-wider">{caption}</p>
              }
              title={parseQuote(quote)}
              image={customerImage}
              buttonText={button_text ? button_text : "Bekijk deze case"}
            />
          );
        }}
        listClass="col-span-4 flex"
      />
    </section>
  );
};

export default MoreCustomerExperiencesEntryBlock;
