import Script from "next/script";
import clsx from "clsx";
import { Grid } from "@/components/shared/Grid";

export const Locator: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...props }) => (
  <Grid>
    <div {...props} className={clsx("col-span-full", props?.className)}>
      <div id="hellorider-dealer-locator" />;
      <Script
        id="hellorider-dealer-locator-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            initializeDealerLocatorWidget({
              "maps_key": "AIzaSyDG9rJOghQ7DOPhlDbf9AfZTkRiRHdOtOU", // gitleaks:allow
              "height": 600,
              "zoom_level": 9,
              "color": "#0b1423",
              "show_filter": true,
              "center": {
              "lat": 52.25612305355975,
              "lng": 4.4239353976983375
              },
              "distance": 25,
              "radius_options": [
              5,
              25,
              50
              ],
              "language": "nl",
              "element_id": "hellorider-dealer-locator",
              "lease_company_id": "d3dfa580-0b5e-4d93-8bfd-062782da1d83"
            })
          `,
        }}
      ></Script>
    </div>
  </Grid>
);
