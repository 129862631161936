export const parseDate = (dateString: string) => {
  const parsed = new Date(dateString).toLocaleDateString("nl", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  return parsed === "Invalid Date" ? "" : parsed;
};

export const parseDateNumbers = (dateString: string) => {
  const parsed = new Date(dateString).toLocaleDateString("nl", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });
  return parsed === "Invalid Date" ? "" : parsed;
};
