interface ContactContentProps {
  text: string;
  buttons: React.ReactNode;
}

const ContactContent: React.FC<ContactContentProps> = ({ text, buttons }) => (
  <>
    <p className="whitespace-pre-wrap">{text}</p>
    <div className="tablet:flex-row tablet:gap-8 mt-6 flex flex-1 flex-col gap-4">{buttons}</div>
  </>
);

export default ContactContent;
