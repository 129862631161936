import React from "react";
import Button from "../Button";
import CardIconButton from "../CardIconButton";

interface BlogCardBodyProps {
  caption?: React.ReactNode;
  title?: React.ReactNode;
  buttonText?: string | null;
  darkThemeEnabled?: boolean;
  isHovered: boolean;
}

const BlogCardBody: React.FC<BlogCardBodyProps> = ({
  caption,
  title,
  buttonText,
  darkThemeEnabled,
  isHovered,
}) => {
  return (
    <div className="tablet:px-6 flex flex-1 flex-col justify-between px-10 py-8">
      <div>
        {caption}
        {title && (
          <p className="text-h4-sm tablet:mt-6 tablet:text-h3-sm mt-4 font-sans font-bold tracking-wider">
            {title}
          </p>
        )}
      </div>
      {buttonText ? (
        <Button
          className="tablet:mt-8 mt-6 self-start"
          variant={darkThemeEnabled ? "tertiary" : "secondary"}
          size="small"
          type="div"
        >
          {buttonText}
        </Button>
      ) : (
        <CardIconButton className="tablet:mt-8 mt-6" isHovered={isHovered} />
      )}
    </div>
  );
};

export default BlogCardBody;
