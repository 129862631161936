import BlogCaption from "@/components/pages/BlogOverview/BlogOverviewBlock/BlogCaption";
import { BlogCardProps } from "@/components/shared/BlogCard";
import { BlogsEntryBlockProps } from "../BlogsEntryBlock";

export const mapBlogsEntryBlockPropsToCards = (
  blogs: NonNullable<BlogsEntryBlockProps["blogs"]>
): (BlogCardProps | null)[] => {
  return blogs.map(({ _slug, type, card_title, _publish_on, image }) => {
    const cardImage = image?.[0]?.default;
    if (!_slug || !type || !card_title || !cardImage) return null;
    return {
      link: _slug,
      caption: <BlogCaption type={type} publishOn={_publish_on} />,
      title: card_title,
      image: cardImage,
    };
  });
};
