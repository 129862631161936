import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { Grid } from "@/components/shared/Grid";
import Employee from "@/components/blocks/FaqMiniBlock/Employee";
import Faq from "@/components/blocks/FaqMiniBlock/Faq";
import { mapItems } from "./FaqMiniBlock/mapItems";

export type FaqBlockProps = GetDynamicContentItemProps<"FaqBlock">;

const FaqMiniBlock = ({
  title,
  faq_items,
  faq_button_text,
  faq_button_target,
  contact_title,
  contact_body,
  contact_button_text,
  contact_button_target,
  image,
  className,
}: FaqBlockProps) => {
  const items = mapItems(faq_items);
  if (!items) return null;
  return (
    <div data-block="FaqMiniBlock" className={`relative ${className ?? ""}`}>
      <Grid>
        <Faq
          title={title}
          items={items}
          buttonText={faq_button_text}
          buttonTarget={faq_button_target?.[0]}
          className="tablet:-mr-8 tablet:bg-gradient-to-r bg-none from-[#F3F3F3]/30 to-[#E8E4E3]/30"
        />
        <Employee
          title={contact_title}
          body={contact_body}
          buttonText={contact_button_text}
          buttonTarget={contact_button_target?.[0]}
          image={image?.[0]}
          className="tablet:block hidden bg-gradient-to-br from-[#9A455A] via-[#0B1423] to-[#265E79]"
        />
      </Grid>
      <Grid className="tablet:hidden block bg-gradient-to-br from-[#9A455A] via-[#0B1423] to-[#265E79]">
        <Employee
          title={contact_title}
          body={contact_body}
          buttonText={contact_button_text}
          buttonTarget={contact_button_target?.[0]}
          image={image?.[0]}
        />
      </Grid>
    </div>
  );
};

export default FaqMiniBlock;
