import Script from "next/script";
import clsx from "clsx";
import { Grid } from "@/components/shared/Grid";

export const Calculator: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...props }) => (
  <Grid>
    <div {...props} className={clsx("col-span-full", props?.className)}>
      <div id="hellorider-calculator" />
      <Script
        id="hellorider-calculator-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            initializeCalculatorWidget({
              "color": "#009183",
              "language": "nl",
              "compare_buying": false,
              "mode": "normal",
              "default_msrp": 2500,
              "default_employer_contribution": 0,
              "default_bike_type": "ebike",
              "default_vat": true,
              "element_id": "hellorider-calculator",
              "lease_company_id": "d3dfa580-0b5e-4d93-8bfd-062782da1d83"
            })
          `,
        }}
      ></Script>
    </div>
  </Grid>
);
