import React from "react";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import Cta from "../shared/Cta";

export type CallToActionBlock2Props = GetDynamicContentItemProps<"CallToActionBlock2">;

const CallToActionBlock2: React.FC<CallToActionBlock2Props> = ({
  image,
  text,
  button_text,
  button_target,
  variant = "light",
  className,
}) => {
  const croppedImage = image?.[0];
  if (!text || !button_text || !croppedImage) return null;
  return (
    <section
      data-block="CallToActionBlock2"
      className={`relative ${variant === "light" ? "bg-none" : "bg-grey-light py-8"} ${className}`}
    >
      <Grid>
        <Cta
          text={text}
          buttonText={button_text}
          buttonTarget={button_target?.[0]}
          image={croppedImage}
        />
      </Grid>
    </section>
  );
};

export default CallToActionBlock2;
