import React from "react";
import CardIconButton from "@/components/shared/CardIconButton";

interface SubjectCardContentProps {
  title: string;
  body: string;
  hasLink: boolean;
  isHovered: boolean;
}

const SubjectCardContent: React.FC<SubjectCardContentProps> = ({
  title,
  body,
  hasLink,
  isHovered,
}) => {
  return (
    <>
      <h4 className="text-fuchsia-400-sm tablet:text-h2-sm self-center font-sans font-bold tracking-wider">
        {title}
      </h4>
      <div className="text-p-sm tablet:mt-6 tablet:text-p mt-4 font-serif tracking-wider">
        {body}
      </div>
      {hasLink && <CardIconButton isHovered={isHovered} className="mt-6" />}
    </>
  );
};

export default SubjectCardContent;
